import * as core from 'cw-ui-core';
import { connect } from 'react-redux';

export const ROLES = {
    MP_ADMIN: 'MP Admin',
    MP_SUPPORT_TEAM_LEAD: 'MP Support Team Lead',
    MP_SUPPORT_ELEVATED: 'MP Support Elevated',
    ADMIN: 'Admin',
    BILLING_ADMIN: 'Billing Admin',
    MP_FINANCE_DEPARTMENT_USER: 'MP Finance Department User'
};

const HasRoleAccess = props => {
    const hasAccess = () => {
        if (props.allow) {
            let allowAccess = false;
            for (let role of props.allow) {
                allowAccess = props.roles.includes(role);
                if (props.requireAll && !allowAccess) {
                    break;
                } else if (!props.requireAll && allowAccess) {
                    break;
                }
            }
            return allowAccess;
        }

        return false;
    };

    if (hasAccess()) {
        return props.children;
    }

    return props.fallback ? props.fallback : null;
};

const mapStateToProps = state => {
    const roles = core.getUserRoles(state.oidc);
    let rolesParsed;
    if (roles) {
        rolesParsed = typeof roles === 'string' || roles instanceof String ? [roles] : roles.toJS();
    }
    return {
        roles: rolesParsed ? rolesParsed : [],
    };
};

export default connect(mapStateToProps)(HasRoleAccess);
